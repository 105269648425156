import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AlertController } from '@ionic/angular';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, timeout } from 'rxjs/operators';

import { StorageService } from '../services/auth/storage.service';
import ErrorResponseDTO from '../interfaces/ErrorResponse.dto';
import { MessageService } from 'primeng/api';
import { Toast } from '../enumerations/toast.enum';
import { LoaderService } from '../services/app-loader/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private timeoutTiming = 10000;
  constructor(
    public storage: StorageService,
    public alertContr: AlertController,
    private toastService: MessageService,
    private loaderService: LoaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);
    this.loadBlock(true);
    return (
      next
        .handle(req)
        //Pipe para Erros
        .pipe(
          catchError((response) => {
            const {statusText} = response;
            if(statusText === 'Unknown Error'){
              return throwError('Timeout');
            }

            let error: ErrorResponseDTO = response.error;
            if (!error.status) {
              try {
                error = {
                  mensagens: error.mensagens,
                  status: response.status
                }
              } catch (e) {}
            }

            switch (Number(error.status)) {
              case 403:
                this.handle403(this.listErrors(error));
                break;

              case 401:
                this.handle401(this.listErrors(error));
                break;

              case 422:
                this.handle422(this.listErrors(error));
                break;

              case 404:
                this.handle404(this.listErrors(error));
                break;

              default:
                this.handleDefaultError(error);
            }

            return throwError(error);
          })
        )
        //Pipe para Timeout
        .pipe(
          timeout(this.timeoutTiming),
          catchError((response) => {
            if(response !== 'Timeout'){
              return;
            }
            const error: ErrorResponseDTO = {
              mensagens: ['Tempo de espera excedido'],
              status: '408',
            };
            this.handle408(this.listErrors(error));
            return throwError(error);
          })
        )
        //Pipe para loading de requisições
        .pipe(
          finalize(() => {
            this.loaderService.isLoading.next(false);
            this.loadBlock(false);
          })
        ) as any
    );
  }

  handle401(error: string) {
    this.presentToast(
      'warn',
      '401 - Não autorizado',
      error,
      Toast.mediumDuration
    );
  }

  handle403(error: string) {
    this.presentToast('warn', '403 - Proibido', error, Toast.mediumDuration);
    //this.storage.setLocalUser(null);
  }

  handle404(error: string) {
    this.presentToast(
      'warn',
      '404 - Não encontrado',
      error,
      Toast.mediumDuration
    );
  }

  handle408(error: string) {
    this.presentToast(
      'error',
      '408 - Request Timeout',
      error,
      Toast.longDuration
    );
  }

  async handle422(error: string) {
    this.presentToast(
      'error',
      '422 - Não processável',
      error,
      Toast.longDuration
    );
  }

  handleDefaultError(error: ErrorResponseDTO) {
    const status = error.status;
    const message = this.listErrors(error);
    this.presentToast('error', status.toString(), message, Toast.longDuration);
  }

  private listErrors(error: ErrorResponseDTO): string {
    const { mensagens } = error;

    let s = '';

    for (const m of mensagens) {
      s = s + m;
    }

    return s;
  }

  private presentToast(
    severity: string,
    summary: string,
    detail: string,
    life: Toast
  ) {
    this.toastService.clear();
    this.toastService.add({
      severity,
      summary,
      detail,
      life,
    });
  }

  private async createAlert(error) {
    let titulo = error.error;
    let message = error.msg;

    if (!titulo) {
      titulo = 'Falha';
    }

    if (!message) {
      message = error.message;
    }

    const alert = await this.alertContr.create({
      header: error.status + ': ' + titulo,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
        },
      ],
    });

    await alert.present();
  }

  private loadBlock(isBlock = false): void {
    const value = isBlock? 'none': 'all';
    document.documentElement.style.setProperty('--load-block', value);
  }
}

export const errorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
