/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'ferramentas',
    loadChildren: () => import('./pages/ferramentas/ferramentas.module').then( m => m.FerramentasPageModule)
  },
  {
    path: 'estrutura',
    loadChildren: () => import('./pages/estrutura/estrutura.module').then( m => m.EstruturaPageModule)
  },
  {
    path: 'contato',
    loadChildren: () => import('./pages/contato/contato.module').then( m => m.ContatoPageModule)
  },
  {
    path: 'acompanhamento',
    loadChildren: () => import('./pages/acompanhamento/acompanhamento.module').then( m => m.AcompanhamentoPageModule),
    canLoad: [false],
  },
  {
    path: 'aplicativos',
    loadChildren: () => import('./pages/aplicativos/aplicativos.module').then( m => m.AplicativosPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
