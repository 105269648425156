import { Pipe, PipeTransform } from '@angular/core';
import { IPIRANGA, ICONIC, AMPM } from '../../consts/Empresa.const';

@Pipe({
  name: 'empresaPipe',
})
export class EmpresaPipe implements PipeTransform {
  transform(value: any): string {
    switch (value) {
      case IPIRANGA.id:
        return IPIRANGA.description;
        break;

      case AMPM.id:
        return AMPM.description;
        break;

      case ICONIC.id:
        return ICONIC.description;
        break;
    }

    return value;
  }
}
