import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpresaPipe } from './empresa-pipe/empresa.pipe';
import { EmptyPipe } from './utils/empty.pipe';

@NgModule({
  declarations: [EmpresaPipe, EmptyPipe],
  imports: [CommonModule,],
  exports: [EmpresaPipe, EmptyPipe],
})
export class PipeModule {}
