import { Injectable } from '@angular/core';
import { STORAGE_KEYS } from 'src/config/storageKeys.config';
import LocalUserDTO from '../../interfaces/LocalUser.sto';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getLocalUser(): LocalUserDTO {
    const user = localStorage.getItem(STORAGE_KEYS.localUser);
    if (user == null) {
      return null;
    }
    else {
      return JSON.parse(user);
    }
  }

  setLocalUser(obj: LocalUserDTO) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.localUser);
    }
    else {
      localStorage.setItem(STORAGE_KEYS.localUser, JSON.stringify(obj));
    }

  }
}
