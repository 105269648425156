import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_CONFIG } from '../../../config/api.config';
import { StorageService } from '../services/auth/storage.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private storage: StorageService,
    private toastService: MessageService,
    ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.toastService.clear();
    const localUser = this.storage.getLocalUser();

    const N = API_CONFIG.baseURL.length;
    const reqToApi = req.url.substring(0, N) === API_CONFIG.baseURL;

    if (localUser && reqToApi) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + localUser.token),
      });
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}

export const authInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
