import { Component, ElementRef, HostListener } from '@angular/core';

import { MenuItem, PrimeIcons } from 'primeng/api';

import { PreferencesService } from './shared/services/preferences/preferences.service';
import { ThemeService } from './shared/services/theme/theme.service';
import { AuthService } from './shared/services/auth/auth.service';
import { LoaderService } from './shared/services/app-loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isDarkTheme = false;

   appPages  = [
    { title: 'Home', url: '/home', icon: 'pi-home' },
    { title: 'Ferramentas', url: '/ferramentas', icon: 'pi-cog' },
    { title: 'Estrutura', url: '/estrutura', icon: 'pi-sitemap' },
    { title: 'Aplicativos', url: '/aplicativos', icon: 'pi-mobile' },
    { title: 'Contato', url: '/contato', icon: 'pi-users' },
  ];


  constructor(
    private preferencesService: PreferencesService,
    private themeService: ThemeService,
    private auth: AuthService,
    public loaderService: LoaderService,
  ) {
    const theme = this.preferencesService.getThemePreference();
    document.body.setAttribute('color-theme', theme);

    if (theme === 'dark') {
      this.isDarkTheme = true;
      this.themeService.switchTheme('bootstrap4-dark-yellow');
    } else {
      this.isDarkTheme = false;
      this.themeService.switchTheme('saga-blue');
    }
  }

  toggleDetails(p) {
    if (p.showDetails) {
      p.showDetails = false;
      p.icon = 'ios-arrow-down';
    } else {
      p.showDetails = true;
      p.icon = 'ios-arrow-up';
    }
  }

  toggleThemes(event) {
    const { checked } = event.detail;
    if (checked) {
      document.body.setAttribute('color-theme', 'dark');
      this.themeService.switchTheme('bootstrap4-dark-yellow');
      this.preferencesService.setThemePreference('dark');
    } else {
      document.body.setAttribute('color-theme', 'light');
      this.preferencesService.setThemePreference('light');
      this.themeService.switchTheme('saga-blue');
    }
  }

  clickOnMenu(event){
    console.log(event);
  }

  logout() {
    this.auth.logout();
  }

  externalHandle(
    link: string = 'https://grupoultracloud.sharepoint.com/teams/MobileCOE/SitePages/Mobile-COE.aspx'
  ): void {
    window.open(link, '_blank');
  }
}
