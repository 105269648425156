/* eslint-disable @typescript-eslint/naming-convention */

import Enum from '../interfaces/Enum';

const IPIRANGA: Enum = {
  id: '00007',
  description: 'Ipiranga',
};
const AMPM: Enum = {
  id: '00032',
  description: 'AMPM',
};

const ICONIC: Enum = {
  id: '00093',
  description: 'Iconic',
};

export { IPIRANGA, AMPM, ICONIC };
