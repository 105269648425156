import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG, AUTH_GATEWAY } from 'src/config/api.config';
import CredenciaisDTO from '../../interfaces/Credencias.dto';
import LocalUserDTO from '../../interfaces/LocalUser.sto';
import { StorageService } from './storage.service';
import { PreferencesService } from '../preferences/preferences.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    )
  {}

  authenticate(credenciais: CredenciaisDTO): Observable<LocalUserDTO> {
    return this.http.post<LocalUserDTO>(`${AUTH_GATEWAY.baseURL}/sessions`, credenciais, {
      responseType: 'json',
    });
  }

  refreshToken() {
    return this.http.post(
      `${AUTH_GATEWAY.baseURL}auth/refresh_token`,
      {},
      {
        observe: 'response',
        responseType: 'text',
      }
    );
  }

  loginSucesso(logedUser: LocalUserDTO) {
    const {token, cdUser} = logedUser;

    const user: LocalUserDTO = {
      token,
      cdUser
    };

    this.storage.setLocalUser(user);
  }

  logout() {
    //this.preferencesService.setThemePreference('light');
    this.storage.setLocalUser(null);
}
}
