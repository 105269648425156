import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import {TabMenuModule} from 'primeng/tabmenu';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { DialogService } from 'primeng/dynamicdialog';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { authInterceptorProvider } from './shared/interceptors/auth-interceptor';
import { errorInterceptorProvider } from './shared/interceptors/error-interceptor';
import { PipeModule } from './shared/pipes/pipe.module';
import { DirectivesModule } from './shared/directive/directives.module';
import { GenericDialogModule } from './shared/components/utils/generic-dialog/generic-dialog.module';

@NgModule({
  declarations: [AppComponent,],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TabMenuModule,
    ToastModule,
    GenericDialogModule,
    PipeModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    DirectivesModule,

  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MessageService,
    ConfirmationService,
    DialogService,
    authInterceptorProvider,
    errorInterceptorProvider,
  ],
  bootstrap: [AppComponent],
  exports: [ToastModule, PipeModule, ProgressBarModule],
})
export class AppModule {}
