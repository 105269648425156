import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  constructor() { }

  setThemePreference(themePreference: string): void{
    localStorage.setItem('theme-preference', themePreference );
  }

  getThemePreference(): string{
    return localStorage.getItem('theme-preference') || 'light';
  }
}
